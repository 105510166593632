import React, { useEffect, useState } from "react";
import {
  getNotificationConnection,
  getPdfGeneratorConnection,
} from "utils/websocket";

const WebsocketContext = React.createContext({
  notificationSession: null,
  pdfGeneratorSession: null,
  downloadsQueue: null,
  handleDownload: () => null,
  removeFromDownloadQueue: () => () => null,
});

const WebsocketProvider = ({ children }) => {
  const [notificationSession, setNotificationSession] = useState(null);
  const [pdfGeneratorSession, setPdfGeneratorSession] = useState(null);
  const [downloadsQueue, setDownloadsQueue] = useState([]);
  const [newDownloadEvent, setNewDownloadEvent] = useState(null);

  useEffect(() => {
    setDownloadsQueue((downloadsQueue) =>
      downloadsQueue.map((item) => {
        if (item.id === newDownloadEvent.id && !item.result) {
          return { ...item, ...newDownloadEvent };
        }
        return item;
      })
    );
  }, [newDownloadEvent]);

  const removeFromDownloadQueue = (id) => () => {
    setDownloadsQueue((downloadsQueue) =>
      downloadsQueue.filter((item) => {
        return item.id !== id;
      })
    );
  };

  const handleDownload = (data) => {
    if (pdfGeneratorSession) {
      const selected = downloadsQueue.find((item) => item.id === data.id);
      if (!selected) {
        const newDownloadsQueue = {
          id: data.id,
          fetching: true,
          info: {
            name: data.sector,
            progress: 0,
          },
          result: null,
        };
        setDownloadsQueue([...downloadsQueue, newDownloadsQueue]);
        pdfGeneratorSession
          .call(
            "ai.datagram.pdf_generator.backend.sales_plan.sales_report",
            [data],
            {},
            { receive_progress: true }
          )
          .then(
            (response) => {
              setNewDownloadEvent({
                id: data.id,
                fetching: false,
                result: response.results,
              });
            },
            (err) => {
              console.log("pdf_generator failed with error:", err);
            },
            (info) => {
              setNewDownloadEvent({
                id: data.id,
                fetching: true,
                info,
              });
            }
          );
      }
    }
  };

  const setupNotificationConnection = () => {
    const notificationConnection = getNotificationConnection();
    notificationConnection.onopen = (session, details) => {
      console.log("Connected:", details);
      setNotificationSession(session);
    };
    notificationConnection.onclose = (reason, details) => {
      //console.log("Connection closed:", details);
      setNotificationSession(null);
      setTimeout(() => {
        if (!notificationConnection._is_retrying) {
          notificationConnection.open();
        }
      }, 5000); // Delayed for 5 second.
    };
    notificationConnection.open();
  };

  const setupPdfGeneratorConnection = () => {
    const pdfGeneratorConnection = getPdfGeneratorConnection();
    pdfGeneratorConnection.onopen = (session, details) => {
      console.log("Connected:", details);
      setPdfGeneratorSession(session);
    };
    pdfGeneratorConnection.onclose = (reason, details) => {
      //console.log("Connection closed:", details);
      setPdfGeneratorSession(null);
      setTimeout(() => {
        if (!pdfGeneratorConnection._is_retrying) {
          pdfGeneratorConnection.open();
        }
      }, 5000); // Delayed for 5 second.
    };
    pdfGeneratorConnection.open();
  };

  useEffect(() => {
    setupNotificationConnection();
    setupPdfGeneratorConnection();
    return () => {
      setNotificationSession(null);
    };
  }, []);

  return (
    <WebsocketContext.Provider
      value={{
        notificationSession,
        pdfGeneratorSession,
        downloadsQueue,
        handleDownload,
        removeFromDownloadQueue,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export { WebsocketProvider, WebsocketContext };
