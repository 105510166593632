import React, { useEffect, useState } from "react";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Highcharts from "highcharts/highcharts.js";
import Grid from '@mui/material/Grid';
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import NoDataChart from "components/common/NoDataChart";
import SwitchOptions from "../SwitchOptions";
import SwitchOptionsDropdown from "../SwitchOptionsDropdown";
import SwitchMultiOptionsDropdown from "../SwitchMultiOptionsDropdown";
import ExportButton from "components/common/ExportButton";
import ComponentFiltersWrapper from "../ComponentFiltersWrapper";
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import withKpiData from "utils/WithKpiData";
import KpiContainer from "../KpiContainer";
import "./index.scss";
import { useGetChartHeight } from "utils/hooks";
import HighchartsBoost from "highcharts/modules/boost";

// highchartsMore(Highcharts);
// HighchartsHeatmap(Highcharts);
// HighchartsBoost(Highcharts);

const useStyles = makeStyles()(() => ({
  filterRightContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filterLeftContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  right: {
    marginLeft: 'auto'
  }
}));

const UniversalChart = (props) => {
  const {
    component,
    constructorType,
    componentName,
    HighchartsComponent,
    data,
    translate,
    requestHandler: fetchUniversalChatData,
    gridInformation,
  } = props;
  const [nodata, setNodata] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionDropDown, setSelectedOptionDropDown] = useState(null);
  const [selectedMultiOption, setSelectedMultiOption] = useState(null);
  const [selectedLeftMultiOption, setSelectedLeftMultiOption] = useState({});
  const [priceRangeValue, setPriceRangeValue] = useState();
  const { classes } = useStyles();

  const subtractedHeight = () => {
    // chart height
    let result = 0;
    if (
      data.switch_option_multiple ||
      data.switch_options_dropdown ||
      data.switch_options ||
      data.step_size
    )
      result -= 51;

    return result;
  };

  // ref to get KpiContainer children ref information
  const [childRef, chartHeight, chartWidth] = useGetChartHeight(
    gridInformation,
    subtractedHeight()
  );

  useEffect(() => {
    if (data.switch_option_multiple)
      setSelectedMultiOption(
        data.switch_option_multiple.values
          .filter((el) => el.selected === true)
          .map((el) => el.value)
      );
    if (data.switch_options) {
      setSelectedOption(
        data.switch_options.find((el) => el.selected === true)
          ? data.switch_options.find((el) => el.selected === true).value
          : null
      );
    }
    if (data.switch_options_dropdown) {
      setSelectedOptionDropDown(
        data.switch_options_dropdown.find((el) => el.selected === true)
          ? data.switch_options_dropdown.find((el) => el.selected === true).value
          : null
      );
    }
    setPriceRangeValue(data.step_size);
    if (data.left_switch_options_dropdowns) {
      let left_switch_options_dropdowns = {}
      Object.keys(data.left_switch_options_dropdowns).forEach((item) => {
        if (data.left_switch_options_dropdowns[item].values) {
          left_switch_options_dropdowns[item] = data.left_switch_options_dropdowns[item].values
            .filter((el) => el.selected === true)
            .map((el) => el.value)
        }
      })
      setSelectedLeftMultiOption(left_switch_options_dropdowns);
    }
    setNodata(false);
    if (!data.chart || !data.chart?.series || !data.chart?.series?.length) {
      setNodata(true);
    }
  }, []);

  const requestHandler = (switch_option, switch_option_multiple, selectedLeftMultiOption, payload= {}) => {
    const apiPayload = {
      switch_option: switch_option ? switch_option : selectedOption,
      switch_option_multiple: switch_option_multiple
        ? switch_option_multiple
        : selectedMultiOption,
      step_size: Number(priceRangeValue),
      ...payload,
    };
    if (selectedLeftMultiOption) {
      apiPayload.left_switch_options_dropdowns = selectedLeftMultiOption;
    }
    fetchUniversalChatData(undefined, apiPayload);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    requestHandler(null, null, null, {
      period: value
    });
  };

  const handleMultiOptionChange = (value) => {
    setSelectedMultiOption(value);
    requestHandler(null, null, null, {
      switch_option_multiple: value
    });
  };

  const handleOptionDropDownChange = (value) => {
    requestHandler(null, null, null, {
      switch_options_dropdown: value
    });
  }

  const handleLeftMultiOptionChange = (key, value) => {
    const newSelectedLeftMultiOption = { ...selectedLeftMultiOption, [key]: value }
    setSelectedLeftMultiOption(newSelectedLeftMultiOption);
    requestHandler(null, null, newSelectedLeftMultiOption);
  }

  // New function to return filter components based on available data
  const renderFilters = () => {
    if (data.componentFilters && data.componentFilters.length) {
      return (
        <ComponentFiltersWrapper
          filters={data.componentFilters}
          requestHandler={(newFilters) =>
            requestHandler(null, null, null, { componentFilters: newFilters })
          }
        />
      );
    }
    return (
      <>
        {data.switch_option_multiple && (
          <div className="chart-wrap__input">
            <SwitchMultiOptionsDropdown
              value={selectedMultiOption}
              onChange={handleMultiOptionChange}
              switchOptions={data.switch_option_multiple}
            />
          </div>
        )}
        {data.switch_options_dropdown && (
          <div className="chart-wrap__input">
            <SwitchOptionsDropdown
              value={selectedOptionDropDown}
              onChange={handleOptionDropDownChange}
              switchOptions={data.switch_options_dropdown}
            />
          </div>
        )}
        {data.switch_options && (
          <div className="chart-wrap__input block">
            <SwitchOptions
              value={selectedOption}
              onChange={handleOptionChange}
              switchOptions={data.switch_options}
            />
          </div>
        )}
      </>
    );
  };

  // Helper function to check if the dataset is large
  const isLargeDataset = (chart) => {
    if (!chart || !chart.series) return false;
    
    let totalPoints = 0;
    chart.series.forEach(series => {
      if (series.data) {
        totalPoints += series.data.length;
      }
    });
    
    // Consider data large if it has more than 5000 points
    return totalPoints > 5000;
  };

  const isLargeData = isLargeDataset(data.chart);

  return (
    <KpiContainer
      title={data.title}
      ref={childRef}
      footer={
        data.export && <ExportButton table={data} component={componentName} />
      }
    >
      {nodata && <NoDataChart />}
      {!nodata && data.chart && (
        <>
          <Grid container alignItems="center">
            <Grid>
              <div className={classes.filterLeftContainer}>
                {data.left_switch_options_dropdowns && (
                  Object.keys(data.left_switch_options_dropdowns).map((switch_options_dropdowns, key) => {
                    const el = data.left_switch_options_dropdowns[switch_options_dropdowns];
                    return (<div className="chart-wrap__left_input" key={key}>
                      <SwitchMultiOptionsDropdown
                        value={selectedMultiOption}
                        onChange={(value) => handleLeftMultiOptionChange(switch_options_dropdowns, value)}
                        switchOptions={el}
                      />
                    </div>);
                  }))}
              </div>
            </Grid>
            <Grid className={classes.right}>
              <div className={classes.filterRightContainer}>
                {renderFilters()}
                {
                  data.step_size && (
                    <div className="chart-wrap__input">
                      <div className="universal-chart__price-range">
                        <TextField
                          variant="standard"
                          value={priceRangeValue}
                          onBlur={(e) =>
                            setPriceRangeValue(
                              e.target.value > 0 ? e.target.value : 0
                            )
                          }
                          onChange={(e) => setPriceRangeValue(e.target.value)}
                          id="outlined-number"
                          label={translate.universalChart.priceRange}
                          type="number"
                          className="universal-chart__price-range__input"
                          placeholder={translate.universalChart.priceRange} />
                        <Button
                          onClick={() =>
                            requestHandler(componentName, selectedOption)
                          }
                          color="primary"
                          variant="contained"
                          className="universal-chart__price-range__button"
                        >
                          {translate.buttons.validate}
                        </Button>
                      </div>
                    </div>
                  )
                }
              </div>
            </Grid>
          </Grid>
          <HighchartsReact
            options={{
              ...data.chart,
              boost: isLargeData ? {
                enabled: true,
                useGPUTranslations: true,
              } : {
                enabled: false
              },
              plotOptions: {
                ...(data.chart.plotOptions || {}),
                series: {
                  ...(data.chart.plotOptions?.series || {}),
                  animation: !isLargeData,
                  turboThreshold: isLargeData ? 0 : 1000, // Only disable threshold for large datasets
                },
              },
              chart: {
                ...data.chart.chart,
                height: chartHeight,
                width: chartWidth,
                animation: !isLargeData
              },
            }}
            highcharts={Highcharts}
            immutable={isLargeData}
            allowChartUpdate={!isLargeData}
            updateArgs={[!isLargeData, !isLargeData, !isLargeData]}
          />
        </>
      )}
    </KpiContainer >
  );
};

export default withKpiData(React.memo(UniversalChart));
