import React, { useState } from "react";
import ComponentFilters, { FilterItem } from "../ComponentFilters";

interface ComponentFiltersWrapperProps {
  filters: FilterItem[];
  requestHandler: (updatedFilters: Record<string, any>) => void;
}

const ComponentFiltersWrapper: React.FC<ComponentFiltersWrapperProps> = ({ filters, requestHandler }) => {
  // Initialize filters with proper key and default value.
  const initialFilters: FilterItem[] = filters.map(f => ({
    ...f,
    key: f.key || (f as any).name || f.type,
    value: f.value !== undefined ? f.value : null,
    switchOptions: f.switchOptions || (f as any).values,
  }));

  const [localFilters, setLocalFilters] = useState<FilterItem[]>(initialFilters);

  const handleFiltersChange = (updatedFilters: FilterItem[]) => {
    let newFilters: Record<string, any> = {};
    updatedFilters.forEach(filter => {
      newFilters[filter.key] = filter.value
        ? (["string", "number"].includes(typeof filter.value)
            ? [filter.value]
            : filter.value)
        : filter.switchOptions.filter((el: any) => el.selected === true).map((el: any) => el.value);
    });
    setLocalFilters(updatedFilters);
    requestHandler(newFilters);
  };

  return (
    <ComponentFilters filters={localFilters} requestHandler={handleFiltersChange} />
  );
};

export default ComponentFiltersWrapper;
