import { makeStyles, withStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import PropTypes from "prop-types";
import { forwardRef } from "react";

const useStyles = makeStyles()({
  title: {
    padding: "15px !important",
    borderBottom: "1px solid rgb(224, 224, 224)",
    fontSize: "16px",
    textAlign: "center",
  },
  footer: {
    borderTop: "1px solid rgb(224, 224, 224)",
  },
  paper: {
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateRows: "max-content auto max-content",
  },
  childrenContainer: {
    height: "100%",
    overflow: "auto",
  },
});

const KpiContainer = (
  { title, children, className, footer, style, refPaper = false },
  ref
) => {
  const { classes } = useStyles();
  if (refPaper) {
    return (
      <Paper
        style={style}
        elevation={0}
        square
        className={`${classes.paper} ${className}`}
        ref={ref}
      >
        {title && <div className={classes.title}>{title}</div>}
        <div className={classes.childrenContainer}>{children}</div>
        {footer && <div className={classes.footer}>{footer}</div>}
      </Paper>
    );
  }
  return (
    <Paper
      style={style}
      elevation={0}
      square
      className={`${classes.paper} ${className}`}
    >
      {title && <div className={classes.title}>{title}</div>}
      <div ref={ref} className={classes.childrenContainer}>
        {children}
      </div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </Paper>
  );
};

const ForwardRefKpiContainer = forwardRef(KpiContainer);
ForwardRefKpiContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  refPaper: PropTypes.bool,
};
ForwardRefKpiContainer.defaultProps = {
  title: "",
  className: "",
  refPaper: false,
};
export default ForwardRefKpiContainer;
