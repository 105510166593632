import React, { useState } from 'react';
import SwitchMultiOptionsDropdown from '../SwitchMultiOptionsDropdown';
import SwitchOptionsDropdown from '../SwitchOptionsDropdown';
import SwitchOptions from '../SwitchOptions';

export interface FilterItem {
  key: string;
  type: 'dropdownMultiple' | 'dropdownSingle' | 'tabs';
  value: any;
  switchOptions: any[];
  label?: string;
}

export interface ComponentFiltersProps {
  filters: FilterItem[];
  requestHandler: (updatedFilters: FilterItem[]) => void;
}

const ComponentFilters: React.FC<ComponentFiltersProps> = ({ filters, requestHandler }) => {
  const [localFilters, setLocalFilters] = useState<FilterItem[]>(filters);

  const handleChange = (filterKey: string, newValue: any) => {
    console.log('Filter key:', filterKey);
    console.log('New value:', newValue);
    const updatedFilters = localFilters.map(filter =>
      filter.key === filterKey ? { ...filter, value: newValue } : filter
    );
    setLocalFilters(updatedFilters);
    requestHandler(updatedFilters);
  };

  return (
    <>
      {localFilters.map(filter => {
        // Render the corresponding filter based on its type
        switch (filter.type) {
          case 'dropdownMultiple':
            return (
              <div className="chart-wrap__input" key={filter.key}>
                <SwitchMultiOptionsDropdown
                  value={filter.value}
                  onChange={(v: any) => handleChange(filter.key, v)}
                  switchOptions={{ values: filter.switchOptions }}
                  label={filter.label}
                />
              </div>
            );
          case 'dropdownSingle':
            return (
              <div className="chart-wrap__input" key={filter.key}>
                <SwitchOptionsDropdown
                  value={filter.value}
                  onChange={(v: any) => handleChange(filter.key, v)}
                  switchOptions={filter.switchOptions}
                />
              </div>
            );
          case 'tabs':
            return (
              <div className="chart-wrap__input" key={filter.key}>
                <SwitchOptions
                  value={filter.value}
                  onChange={(v: any) => handleChange(filter.key, v)}
                  switchOptions={filter.switchOptions}
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default ComponentFilters;
